import { gql } from '@apollo/client'
import { SKU_BASICS, NOTE_BASICS } from '../organization/fragments'
import { INVENTORY_PRICING_BASE_FIELDS } from '../inventory'

/*
  We must repeat the fields in each object because the
  the Apollo codegen doesn't work with template strings.
*/

export const PERSON_INVENTORIES = gql`
  ${INVENTORY_PRICING_BASE_FIELDS}
  fragment PersonInventories on Inventory {
    id
    serialNumber
    conditionStatus
    renewalDate
    organization {
      name
    }
    deployStatus
    deployReason
    sku {
      vendor {
        name
        code
      }
      skuImages
      skuInformation {
        productTitle
      }
      metadata {
        id
        field
        value
      }
    }
    pricing {
      ...InventoryPricingBaseFields
    }
    firstbaseSupplied
    gift
    description
  }
`

export const PERSON_REPLACEMENTS = gql`
  fragment PersonReplacements on ReplacementResponse {
    id
    createdAt
    status
    order {
      id
      products {
        id
        status
        trackingLink
        serialNumber
        returnReasonComment
        inventory {
          id
          description
        }
        sku {
          vendor {
            name
            code
          }
          skuImages
          skuInformation {
            productTitle
          }
          metadata {
            id
            field
            value
          }
        }
      }
    }
    returnOrder {
      id
      products {
        id
        status
        trackingLink
        serialNumber
        returnReasonComment
        inventory {
          id
          description
        }
        sku {
          vendor {
            name
            code
          }
          skuImages
          skuInformation {
            productTitle
          }
          metadata {
            id
            field
            value
          }
        }
      }
    }
  }
`

export const PERSON_PACKAGE_BASICS = gql`
  ${SKU_BASICS}
  ${NOTE_BASICS}
  fragment PersonPackageBasics on PersonPackage {
    name
    slug
    id
    skus {
      ...SKUBasics
    }
    availableSkus {
      skus {
        ...SKUBasics
      }
      category {
        id
        nameSingular
        namePlural
        code
        precedence
        active
        skuType
      }
      availableQuantity
      optionalInOrder
    }
    notes {
      ...NoteBasics
    }
  }
`

export const PERSON_PROFILE_BASIC = gql`
  ${PERSON_PACKAGE_BASICS}
  fragment PersonProfileBasic on Person {
    id
    forename
    surname
    email
    packages {
      ...PersonPackageBasics
    }
    appAccess
    status
    startDate
  }
`

export const PERSON_PROFILE_FULL = gql`
  ${PERSON_PROFILE_BASIC}
  ${PERSON_REPLACEMENTS}
  ${PERSON_INVENTORIES}
  fragment PersonProfileFull on Person {
    ...PersonProfileBasic
    secondaryEmail
    address {
      addressLine1
      addressLine2
      administrativeArea
      countryCode
      locality
      postalCode
      phoneNumber
    }
    offboardingInfo {
      offboardingWithInventory
      returnConfirmed
      lastDate
    }
    inventories {
      ...PersonInventories
    }
    orders {
      id
      createdAt
      person {
        offboardingInfo {
          equipmentComment
        }
      }
      products {
        id
        etaStart
        etaEnd
        status
        trackingLink
        serialNumber
        returnReason
        returnReasonComment
        inventory {
          id
          deployStatus
          description
        }
        sku {
          id
          vendor {
            name
            code
          }
          category {
            skuType
          }
          skuImages
          skuInformation {
            productTitle
          }
          metadata {
            id
            field
            value
          }
        }
        expeditedShipping {
          id
          availableForAll
          option
        }
      }
      orderType
      orderStatus
      etaStart
      etaEnd
      productPackage {
        name
      }
    }
    replacements {
      ...PersonReplacements
    }
    groups
  }
`

export const NOTIFICATIONS = gql`
  fragment Notifications on Person {
    emailNotifications {
      id
      type
    }
  }
`

export const SELF_PROFILE_SLOW = gql`
  ${PERSON_PACKAGE_BASICS}
  ${INVENTORY_PRICING_BASE_FIELDS}
  fragment SelfProfileSlow on Person {
    id
    inventories {
      id
      serialNumber
      conditionStatus
      renewalDate
      description
      organization {
        name
      }
      deployStatus
      deployReason
      sku {
        vendor {
          name
          code
        }
        category {
          skuType
        }
        skuImages
        skuInformation {
          productTitle
        }
        metadata {
          id
          field
          value
        }
      }
      pricing {
        ...InventoryPricingBaseFields
      }
      firstbaseSupplied
      gift
    }
    packages {
      ...PersonPackageBasics
    }
  }
`

export const SELF_PROFILE_FAST = gql`
  ${NOTIFICATIONS}
  fragment SelfProfileFast on Person {
    id
    slug
    forename
    surname
    email
    appAccess
    status
    startDate
    secondaryEmail
    address {
      addressLine1
      addressLine2
      administrativeArea
      countryCode
      locality
      postalCode
      phoneNumber
    }
    offboardingInfo {
      returnOrderId
      returnConfirmed
      equipmentComment
      offboardingWithInventory
      deviceReturnMethod {
        id
        type
        preferredHours
        preferredDays
        unavailableDatesComment
        buildingAccessComment
      }
      furnitureReturnMethod {
        id
        disassemblingRequired
        stairsAccess
        preferredDays
        unavailableDatesComment
        buildingAccessComment
      }
    }
    groups
    ...Notifications
  }
`

export const SELF_PROFILE_FULL = gql`
  ${SELF_PROFILE_FAST}
  ${SELF_PROFILE_SLOW}
  fragment SelfProfileFull on Person {
    ...SelfProfileFast
    ...SelfProfileSlow
  }
`
