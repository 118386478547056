import React, { forwardRef, TextareaHTMLAttributes, useState } from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import classNamesBind from 'classnames/bind'

import Label from '@firstbase/components/Atoms/Label'
import Error from '@firstbase/components/Atoms/Error'
import MinusIcon from '@firstbase/assets/icons/minus.svg'
import PlusIcon from '@firstbase/assets/icons/plus.svg'
import Loader from '@firstbase/components/Atoms/Loader'

import css from './Input.module.css'

const classNames = classNamesBind.bind(css)

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

interface OwnProps extends InputProps {
  property?: string
  value?: string | number
  defaultValue?: string | number
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
  label?: string
  subLabel?: string
  htmlFor?: string
  disabled?: boolean
  textArea?: boolean
  alignRow?: boolean
  inputStyles?: React.CSSProperties
  step?: number
  min?: string | number
  max?: string | number
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  loading?: boolean
  loadingHelperText?: string
}

const Input = forwardRef<HTMLTextAreaElement | HTMLInputElement, OwnProps>(
  (
    {
      property,
      value,
      defaultValue,
      error,
      label,
      subLabel,
      htmlFor,
      disabled,
      textArea,
      onChange,
      alignRow,
      inputStyles,
      min,
      max,
      step,
      loading,
      loadingHelperText,
      ...rest
    }: OwnProps,
    ref
  ) => {
    const [randomId] = useState(
      `${label}-${Math.random().toString(16).slice(2)}`
    )

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return null
      if (rest.type === 'number') {
        const newValue = Number(event.target.value)
        if (
          (min && Number(min) > newValue) ||
          (max && Number(max) < newValue)
        ) {
          return null
        }
      }

      return onChange(event)
    }

    const renderLabel = () => {
      if (!label) return null
      return (
        <Label
          label={label}
          subLabel={subLabel}
          htmlFor={htmlFor || randomId}
        />
      )
    }

    const renderError = () => {
      if (!error) return null
      return <Error label={error} />
    }

    const showCounters = rest.type === 'number'

    const renderIncrements = () => {
      if (!showCounters) return null

      const stepAmount = step || 1

      const _decrement = () => {
        if (min && Number(value) === min) return null

        return handleChange({
          target: { value: Number(value) - stepAmount },
        } as unknown as React.ChangeEvent<HTMLInputElement>)
      }

      const _increment = () => {
        if (max && Number(value) === max) return null

        return handleChange({
          target: { value: Number(value) + stepAmount },
        } as unknown as React.ChangeEvent<HTMLInputElement>)
      }

      const isStepperDisabled = (bound: InputProps['max']) =>
        disabled || (bound && Number(value) === bound)

      return (
        <div className={classNames('counters')}>
          <div
            role="button"
            tabIndex={0}
            className={classNames('counter', {
              disabled: isStepperDisabled(min),
            })}
            onKeyDown={_decrement}
            onClick={_decrement}
          >
            <MinusIcon />
          </div>
          <div
            role="button"
            tabIndex={0}
            className={classNames('counter', {
              disabled: isStepperDisabled(max),
            })}
            onKeyDown={_increment}
            onClick={_increment}
          >
            <PlusIcon fill="inherit" />
          </div>
        </div>
      )
    }

    const renderInput = () => {
      const defaultParamaters: React.HTMLProps<
        HTMLTextAreaElement | HTMLInputElement
      > = {
        defaultValue,
        disabled,
        onChange: handleChange,
        value,
        id: htmlFor || randomId,
      }

      if (textArea) {
        return (
          <textarea
            ref={ref as any}
            className={classNames('textarea', {
              errorState: error,
              active: value,
              hasCounter: showCounters,
            })}
            data-property={property}
            {...defaultParamaters}
            {...(rest as TextareaHTMLAttributes<any>)}
          />
        )
      }

      return (
        <input
          ref={ref as any}
          className={classNames('input', {
            errorState: error,
            active: value,
            hasCounter: showCounters,
          })}
          data-property={property}
          {...defaultParamaters}
          {...rest}
        />
      )
    }

    return (
      <div className={classNames('wrapper')}>
        <div
          // eslint-disable-next-line object-shorthand
          className={classNames('container', { disabled: disabled, alignRow })}
        >
          {renderLabel()}
          <div style={inputStyles} className={classNames('input-wrapper')}>
            {renderInput()}
            {renderIncrements()}
            {loading && (
              <div className={classNames('loading-indicator')}>
                <Loader size={2} />
              </div>
            )}
          </div>
        </div>
        {loading && loadingHelperText && (
          <div className={classNames('loading-text')}>{loadingHelperText}</div>
        )}
        {!loading && renderError()}
      </div>
    )
  }
)

export default Input
